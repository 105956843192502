/* eslint-disable */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [

    // Dashboard
    {
        id   : 'dashboard',
        title: 'Dashboard',
        type : 'basic',
        icon : 'heroicons_outline:rectangle-group',
        link : '/dashboard',
    },
    // Projects
    {
        id   : 'project-management.projects',
        title: 'Projects',
        type : 'basic',
        icon : 'mat_outline:bubble_chart',
        link : '/project-management/projects',
    },

    // Hire
    {
        id      : 'hire',
        title   : 'Hire',
        type    : 'collapsable',
        icon    : 'heroicons_outline:truck',
        children: [
            // Dashboard
            {
                id   : 'hire.dashboard',
                title: 'Hire Dashboard',
                type : 'basic',
                icon : 'heroicons_outline:rectangle-group',
                link : '/hire/dashboard',
            },
            // Dashboard
            {
                id   : 'hire.asset-board',
                title: 'Asset Board',
                type : 'basic',
                icon : 'heroicons_outline:view-columns',
                link : '/hire/asset-board',
            },
            // Customers
            {
                id   : 'hire.customers',
                title: 'Customers',
                type : 'basic',
                icon : 'heroicons_outline:user-group',
                link : '/hire/customers',
            },
            // Calendar
            {
                id   : 'hire.calendar',
                title: 'Calendar',
                type : 'basic',
                icon : 'heroicons_outline:calendar-days',
                link : '/hire/calendar',
            },
            // Assets
            {
                id   : 'hire.assets',
                title: 'Asset Register',
                type : 'basic',
                icon : 'heroicons_outline:cube-transparent',
                link : '/hire/assets',
            },
            // Purchase Orders
            {
                id   : 'hire.sales-orders',
                title: 'Sales Orders',
                type : 'basic',
                icon : 'heroicons_outline:document',
                link : '/hire/sales-orders',
            },
            // Invoicing
            {
                id   : 'hire.invoicing',
                title: 'Invoicing',
                type : 'basic',
                icon : 'heroicons_outline:document-text',
                link : '/hire/invoicing',
            },
        ],
    },

    // Calibrations
    {
        id      : 'calibration',
        title   : 'Calibration',
        type    : 'collapsable',
        icon    : 'heroicons_outline:wrench-screwdriver',
        children: [
            //     Admin Dashboard
            {
                id   : 'calibration.admin-dashboard',
                title: 'Admin Dashboard',
                type : 'basic',
                icon : 'mat_outline:dashboard',
                link : '/calibration/admin-dashboard',
            },
            //     Workshop Dashboard
            {
                id   : 'calibration.workshop-dashboard',
                title: 'Workshop Dashboard',
                type : 'basic',
                icon : 'mat_outline:space_dashboard',
                link : '/calibration/workshop-dashboard',
            },
            //     Servicing
            {
                id   : 'calibration.servicing',
                title: 'Servicing',
                type : 'basic',
                icon : 'heroicons_outline:wrench',
                link : '/calibration/servicing',
            },
            //     Settings
            {
                id   : 'calibration.settings',
                title: 'Settings',
                type : 'basic',
                icon : 'heroicons_outline:cog-8-tooth',
                link : '/calibration/settings',
            },
            //     Users (to be added into the Nexus settings)
            {
                id   : 'calibration.users',
                title: 'Users',
                type : 'basic',
                icon : 'heroicons_outline:user-group',
                link : '/calibration/users',
            },
            //     Warehouse Locations
            {
                id   : 'calibration.warehouse-locations',
                title: 'Warehouse Locations',
                type : 'basic',
                icon : 'mat_outline:other_houses', //or location //feather:home
                link : '/calibration/warehouse-locations',
            },
            //     Load Cell types
            {
                id   : 'calibration.loadcell-types',
                title: 'Load Cell types',
                type : 'basic',
                icon : 'mat_outline:sensors',
                link : '/calibration/loadcell-types',
            },
            //     Output / Display Types
            {
                id   : 'calibration.display-types',
                title: 'Output / Display Types',
                type : 'basic',
                icon : 'heroicons_outline:tv',
                link : '/calibration/display-types',
            },
            //     SLA
            {
                id   : 'calibration.sla',
                title: 'SLA',
                type : 'basic',
                icon : 'mat_outline:timer',
                link : '/calibration/sla',
            },
            //     Parts register (will likely be added into Hire)
            {
                id   : 'calibration.parts-register',
                title: 'Parts Register',
                type : 'basic',
                icon : 'mat_outline:list_alt',
                link : '/calibration/parts-register',
            },
            //     Machines
            {
                id   : 'calibration.machines',
                title: 'Machines',
                type : 'basic',
                icon : 'heroicons_outline:cog',
                link : '/calibration/machines',
            },
            //     Pressure Test Machines
            {
                id   : 'calibration.pressure-test',
                title: 'Pressure Test Machines',
                type : 'basic',
                icon : 'heroicons_outline:scale',
                link : '/calibration/pressure-test',
            },
            //     Electrical Equipment
            {
                id   : 'calibration.equipment',
                title: 'Electrical Equipment',
                type : 'basic',
                icon : 'mat_outline:electrical_services',
                link : '/calibration/equipment',
            },
            //     Labour Costs
            {
                id   : 'calibration.labour',
                title: 'Labour Costs',
                type : 'basic',
                icon : 'mat_outline:receipt_long',
                link : '/calibration/labour',
            },
            //     Measurements
            {
                id   : 'calibration.measurements',
                title: 'Measurements',
                type : 'basic',
                icon : 'heroicons_outline:calculator',
                link : '/calibration/measurements',
            },
            //     Packaging
            {
                id   : 'calibration.packaging',
                title: 'Packaging',
                type : 'basic',
                icon : 'heroicons_outline:gift-top',
                link : '/calibration/packaging',
            },
            //     Examination Faults
            {
                id   : 'calibration.faults',
                title: 'Examination Faults',
                type : 'basic',
                icon : 'mat_outline:warning_amber', //heroicons_outline:exclamation-triangle
                link : '/calibration/faults',
            },
            //     Rectification Work
            {
                id   : 'calibration.rectification',
                title: 'Rectification Work',
                type : 'basic',
                icon : 'mat_outline:healing', //'mat_outline:build',
                link : '/calibration/rectification',
            },
        ],
    },

    // Settings
    {
        id      : 'settings',
        title   : 'Settings',
        type    : 'basic',
        icon    : 'heroicons_outline:cog-8-tooth',
        link    : '/settings',
    },
];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id      : 'dashboard',
        title   : 'Dashboard',
        tooltip : 'Dashboard',
        type    : 'aside',
        icon    : 'heroicons_outline:home',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id      : 'apps',
        title   : 'Apps',
        tooltip : 'Apps',
        type    : 'aside',
        icon    : 'heroicons_outline:qrcode',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id      : 'pages',
        title   : 'Pages',
        tooltip : 'Pages',
        type    : 'aside',
        icon    : 'heroicons_outline:document-duplicate',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    }
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id      : 'dashboards',
        title   : 'DASHBOARDS',
        type    : 'group',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id      : 'apps',
        title   : 'APPS',
        type    : 'group',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id   : 'others',
        title: 'OTHERS',
        type : 'group',
    },
    {
        id      : 'pages',
        title   : 'Pages',
        type    : 'aside',
        icon    : 'heroicons_outline:document-duplicate',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    }
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id      : 'dashboards',
        title   : 'Dashboards',
        type    : 'group',
        icon    : 'heroicons_outline:home',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id      : 'apps',
        title   : 'Apps',
        type    : 'group',
        icon    : 'heroicons_outline:qrcode',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id      : 'pages',
        title   : 'Pages',
        type    : 'group',
        icon    : 'heroicons_outline:document-duplicate',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    }
];
