import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatSidenavModule } from "@angular/material/sidenav";
import { SharedModule } from "app/core/shared.module";
import { ScrumboardBoardComponent } from "app/views/admin/pages/projects/project/scrumboard/board/board.component";

@Component({
    selector       : 'app-asset-board',
    templateUrl    : './asset-board.component.html',
    standalone     : true,
    imports        : [
        CommonModule,
        SharedModule,
        MatSidenavModule,
        MatButtonModule,
        MatIconModule,
        ScrumboardBoardComponent,
    ]
})
export class AssetBoardComponent
{
    constructor() {

    }
}
