<div class="flex flex-col w-full min-w-0 sm:absolute sm:inset-0 sm:overflow-hidden">
    <mat-drawer-container class="flex-auto sm:h-full">
        <mat-drawer-content class="flex flex-col" style="background-color: #F8F8F8;">

            <!-- Main -->
            <div class="flex-auto px-6 pt-9 pb-12 md:p-8 md:pb-12 lg:p-12">

                <!-- Panel header -->
                <div class="flex items-center relative z-50">
                    <!-- Panel title -->
                    <div class="ml-2 lg:ml-0 text-3xl font-bold tracking-tight leading-none">
                        Asset Board
                    </div>
                </div>

                <!-- Load settings panel -->
                <div class="mt-8">
                    <scrumboard-board [isAssetBoard]="true"></scrumboard-board><!-- [projectId]="4"  -->
                </div>
            </div>
        </mat-drawer-content>
    </mat-drawer-container>
</div>
